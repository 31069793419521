<template>
    <div class="contain">
        <HeaderMenu></HeaderMenu>
        <div class="main">
            <div class="information">
                <div class="information-title">全 | 新 | 直 | 播 | 互 | 动 | 效 | 果 | 抢 | 先 | 体 | 验 | 创 | 新 | 启 | 航</div>
                <div class="information-app">
                    <div class="information-app-icon" @click="LeftSlide" v-bind:class="{ active: (index == 0) }">
                        <svg t="1706957444965" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2753" width="40" height="40"><path d="M330.666667 512a35.84 35.84 0 0 0 11.52 26.453333l235.52 220.586667a35.84 35.84 0 1 0 49.066666-52.48L419.413333 512l207.36-194.56a35.84 35.84 0 1 0-49.066666-52.48L341.333333 484.266667a35.84 35.84 0 0 0-10.666666 27.733333zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="2754"></path></svg>
                    </div>
                    <div class="information-app-image">
                        <div class="slides" id="information-app-image-slide-box">
                            <img src="../assets/images/设置直播间.jpg" alt="">
                            <img src="../assets/images/任务队列状态.jpg" alt="">
                            <img src="../assets/images/事件流程树.jpg" alt="">
                            <img src="../assets/images/定时任务.jpg" alt="">
                            <img src="../assets/images/动画视窗.jpg" alt="">
                            <img src="../assets/images/玩法提示.jpg" alt="">
                            <img src="../assets/images/硬件串口.jpg" alt="">
                            <img src="../assets/images/键鼠模拟.jpg" alt="">
                            <img src="../assets/images/阿里语音.jpg" alt="">
                            <img src="../assets/images/代理商购卡.jpg" alt="">
                            <img src="../assets/images/开放API.jpg" alt="">
                        </div>
                    </div>
                    <div class="information-app-icon" @click="RightSlide" v-bind:class="{ active: (index == 10) }">
                        <svg t="1706957461075" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2955" width="40" height="40"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="2956"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <BottomMenu></BottomMenu>
    </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu"
import BottomMenu from "@/components/BottomMenu"
export default {
    components: { HeaderMenu, BottomMenu },
    data() {
        return {
            index: 0,
        }
    },
    methods: {
        LeftSlide() {
            if (this.index < 1) { return }
            this.index = this.index - 1
            let box = document.getElementById("information-app-image-slide-box")
            box.style.transform = "translateX(calc(-" + (this.index) * 100 + "%))"
        },
        RightSlide() {
            if (this.index > 9) { return }
            console.log(this.index)
            this.index = this.index + 1
            let box = document.getElementById("information-app-image-slide-box")
            box.style.transform = "translateX(calc(-" + (this.index) * 100 + "%))"
        }
    },
    mounted() {
        console.log("index")
    }
}
</script>

<style scoped>
.contain {
    position: relative;
    background-color: #10151B;
    font-family: "cloud";
}

.main {
    width: 100%;
    min-height: 100vh;
}

.active {
    cursor: not-allowed;
}

.information {
    margin: 20px;
    background-color: #1B202A;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
}

.information-title {
    color: #A7B0C7;
    height: 30px;
}
.information-app {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.information-app-image {
    width: 800px;
    height: 450px;
    background-color: #A0BBDC;
    box-shadow: 0 2px 8px 0 rgba(99,99,99,.1);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    transition: transform 0.5s ease;
    max-width: 800px;
}
.slides {
    height: 450px;
    width: 800px;
    display: flex;
    transition: transform 0.5s ease;
}
.slides img {
    flex: 0 0 100%;
    height: auto;
    user-select: none;
}
.information-app-icon {
    background-color: #1B202A;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.information-app-icon:hover {
    background-color: #2F3747;
}

.information-app svg {
}

</style>