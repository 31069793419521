<template>
    <div class="main">
        <div class="main-notice">
            <div class="main-group">
                <img src="../assets/group_qrcode.png" alt="">
                <div>
                    直播互动助手官方 QQ 群
                </div>
            </div>
        </div>
        <div class="main-info">
            <div class="main-info-content">
                <div class="message">
                    <img src="../assets/edgehacker.png" alt="">
                    <div>
                       {{ origin }}
                    </div>
                </div>
            </div>
            <div class="setting">
                <div @click="sendMessage(0)">
                    <span>软件价格收费？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(1)">
                    <span>兼容哪些平台？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(2)">
                    <span>官方群聊在哪？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(3)">
                    <span>能否进行定制？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(4)">
                    <span>包含哪些功能？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(5)">
                    <span>怎样进行反馈？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(6)">
                    <span>视频教程有吗？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
                <div @click="sendMessage(7)">
                    <span>如何联系客服？</span>
                    <svg t="1712135065909" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5071" width="20" height="20"><path d="M682.666667 484.266667l-237.226667-220.586667a35.84 35.84 0 1 0-49.493333 52.48L603.733333 512l-207.786666 193.28a35.84 35.84 0 1 0 49.066666 52.48L682.666667 536.746667a35.84 35.84 0 0 0 0-52.48zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 72.106667a439.466667 439.466667 0 0 1 170.666667 844.373333A439.466667 439.466667 0 0 1 200.533333 200.533333a438.613333 438.613333 0 0 1 311.04-128z" fill="#ffffff" p-id="5072"></path></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            origin: "",
            answerList: [
                "在程序内测期间新邮箱注册默认赠送六个月授权时间，同时在内测期间发现严重异常或提出有重大价值的更新方向，提交并被我们官方工作人员采纳后，还会给予相应的现金奖励！",
                "当前兼容平台包含抖音视频、哔哩哔哩、快手视频、抖音国际版、淘宝直播、AcFun、斗鱼、支付宝直播、微信视频号。",
                "扫描左侧群聊二维码即可进行加入直播互动助手官方 QQ 群，当然也可以搜索 QQ 群号：829242770 进行入群。",
                "如果您需要进行定制的话，在淘宝搜索我们的官方店铺：边缘骇客编程实验室，联系我们淘宝客服，说明您的具体需求，即可进行直播助手定制版的专属定制。",
                "当前产品已经支持互动动画、绿幕视频、玩法提示、硬件连接、语音合成、键鼠模拟等待超丰富的功能。详细功能介绍在帮助文档中给出，点击上方导航栏帮助文档按钮查看详细功能介绍。",
                "如果您在使用本产品时候遇到任何使用问题，都可以点击右上角导航栏反馈空间按钮即可进行反馈，我们的官方工作人员将会在当日进行解答您提交的问题。",
                "直播互动助手提供了帮助文档，如果你通过文档学习比较吃力，建议您进行点击上方导航栏视频教程按钮，观看视频教程学习。",
                "我们的客服都是全天在线的，您只需进入直播互动助手官方 QQ 群，联系群内的管理员即可，或者加管理员为好友私聊即可。"
            ]
        }
    },
    methods: {
        sendMessage(tip) {
            clearInterval(this.originTimer)
            let origin = this.answerList[tip]
            let index = 0
            this.originTimer = setInterval(() => {
                if (index > origin.length) {
                    clearInterval(this.originTimer)
                } else {
                    this.origin = origin.substring(0, index)
                    index = index + 1
                }
            }, 100)
        },
        initMessage() {
            let origin = "我是边缘骇客智能客服机器人！请问您需要什么帮助？请使用快捷键进行提问问题！"
            let index = 0
            this.originTimer = setInterval(() => {
                if (index > origin.length) {
                    clearInterval(this.originTimer)
                } else {
                    this.origin = origin.substring(0, index)
                    index = index + 1
                }
            }, 100)
        }
    },
    mounted() {
        this.initMessage()
    },
    beforeDestroy() {
        clearInterval(this.originTimer)
    }
}
</script>
<style scoped>
.main {
    display: flex;
    padding: 20px;
    font-family: "cloud";
    box-sizing: border-box;
    min-height: 100vh;
}

.main-notice {
    height: 600px;
    width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #1B202A;
    justify-content: center;
}

.main-group {
    margin: 20px;
    padding: 20px;
    background-color: #10151B;
    padding-bottom: 0;
}

.main-group img {
    width: 250px;
    height: 250px;
    display: block;
}

.main-group div {
    height: 60px;
    color: #A7B0C7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-info {
    margin-left: 20px;
    height: 600px;
    box-sizing: border-box;
    background-color: rgba(38, 43, 58, 0.5);
    flex: 1;
    padding: 20px;
}

.main-info-content {
    height: 440px;
}

.message {
    margin: 20px;
    display: flex;
}

.message img {
    height: 40px;
    width: 40px;
    display: block;
    border-radius: 20px;
}

.message div {
    padding: 5px 20px;
    line-height: 30px;
    background-color: #2E3747;
    margin-left: 20px;
    color: #A7B0C7;
    border-radius: 20px;
    max-width: 500px;
}

.setting {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.setting div {
    background-color: #2E3747;
    color: #969EB4;
    width: calc(25% - 15px);
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    padding-right: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
}

.setting div:hover {
    color: #FFFFFF;
}
</style>