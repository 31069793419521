<template>
    <div class="phone-container">
        <div class="main-header">
            <span>边缘骇客编程实验室</span>
            <a href="https://txc.qq.com/products/511669">用户反馈空间</a>
        </div>
        <div class="main-info">
            <img class="main-icon" src="../assets/icon.jpg" alt="">
            <div class="main-info-title">直播互动助手</div>
            <div class="main-info-tip">
                <div>
                    <span>互动动画功能</span><span>绿幕视频功能</span><span>玩法提示功能</span>
                </div>
                <div>
                    <span>硬件连接功能</span><span>语音合成功能</span><span>键鼠模拟功能</span>
                </div>
            </div>
            <div class="main-app">
                <a class="app" target="_blank">
                    <img src="../assets/app/app_1.jpg">
                    <span>完全兼容哔哩哔哩</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_2.jpg">
                    <span>完全兼容抖音直播</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_3.jpg">
                    <span>完全兼容快手直播</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_4.jpg">
                    <span>完全兼容 TIKTOK</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_5.jpg">
                    <span>完全兼容淘宝直播</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_6.jpg">
                    <span>完全兼容 ACFUN</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_7.jpg">
                    <span>完全兼容斗鱼直播</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_8.jpg">
                    <span>完全兼容微信视频号</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_9.jpg">
                    <span>完全兼容拼多多直播</span>
                </a>
                <a class="app" target="_blank">
                    <img src="../assets/app/app_10.jpg">
                    <span>完全兼容支付宝直播</span>
                </a>
            </div>
        </div>

        <div class="menu-box">
            <svg t="1709121655664" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5335" width="50" height="50"><path d="M0 0h1024v1024H0V0z" fill="#0DBC79" opacity=".01" p-id="5336"></path><path d="M512 34.133333a273.1008 273.1008 0 0 1 271.018667 239.445334l0.8192 0.034133A221.866667 221.866667 0 0 1 768 716.8h-136.533333l-3.072-0.273067a17.066667 17.066667 0 0 1-13.755734-13.7216L614.4 699.733333 614.4 512a34.133333 34.133333 0 0 0-30.139733-33.8944L580.266667 477.866667h-136.533334a34.133333 34.133333 0 0 0-33.8944 30.139733L409.6 512v187.733333a17.066667 17.066667 0 0 1-14.0288 16.7936l-3.072 0.273067H256a221.866667 221.866667 0 0 1-23.6544-442.4704l8.635733-0.750933A273.1008 273.1008 0 0 1 512 34.133333z" fill="#0DBC79" p-id="5337"></path><path d="M533.674667 981.1968l177.322666-164.420267a19.0464 19.0464 0 0 0 1.809067-25.429333 16.145067 16.145067 0 0 0-12.629333-6.280533H323.822933c-9.216 0-16.657067 8.055467-16.657066 18.0224 0 5.256533 2.116267 10.24 5.802666 13.653333l177.322667 164.4544a31.402667 31.402667 0 0 0 43.349333 0z" fill="#0DBC79" p-id="5338"></path></svg>
            <div class="main-button">
                <a class="main-button-enter" @click="$router.replace('/purchase')" href="https://edgehacker.lanzn.com/s/livehelper">
                    <span>即刻体验边缘骇客直播互动助手</span>
                </a>
            </div>
        </div>

        <div class="menu-box" style="padding-top: 10px;">
            <div class="main-app">
                <a class="app" href="https://www.mslmsxp.com">
                    <img src="../assets/mathspie_logo.png">
                    <span class="app-filled">秒数联盟数学派</span>
                </a>
                <a class="app" href="https://edgehacker.taobao.com">
                    <img src="../assets/edgehacker_logo.png">
                    <span class="app-filled">边缘骇客淘宝店</span>
                </a>
            </div>
        </div>
        <div class="bottom-blank"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {
        console.log("index")
    }
}
</script>

<style scoped>
.phone-container {
    background-color: #11161C;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: "cloud";
    overflow-y: scroll;
    min-width: 320px;
    max-width: 768px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 15px;
}

.main-header {
    height: 55px;
    background-color: #1B202A;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #343E59;
    justify-content: space-between;
    padding: 0 10px;
}

.main-header span {
    font-size: 20px;
}

.main-header a {
    color: #0DBC79;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #0DBC7988;
    border: 2px solid #0DBC79;
    box-sizing: border-box;
    height: 35px;
    padding: 0 10px;
}

.title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #343E59;
    margin: 10px;
}

.main-info {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: rgba(38, 43, 58, 0.5);
}

.main-icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
}

.main-info-title {
    font-size: 35px;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-image: linear-gradient(45deg, #2cf1e1, #71e575, #96e33e, #bfe33e);
    display: flex;
    align-items: center;
    color: transparent;
    background-clip: text;
    font-weight: bolder;
    animation: change 5s linear alternate infinite;
    justify-content: center;
}

@keyframes change {
    to {
        color: rgba(255, 255, 255, 0.75);
    }
}

.main-info-tip {
    color: #A0BBDC;
    margin-bottom: 20px;
    background-color: #10151B;
    margin: 10px;
    border-radius: 5px;
}

.main-info-tip div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    padding: 0 10px;
}

.main-info-tip div span {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 12px;
}

.main-app {
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
}

.app {
    box-sizing: border-box;
    width: calc(50% - 5px);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    background-color: #10151B;
    margin-bottom: 10px;
    color: #A0BBDC;
    border-radius: 5px;
}

.app span {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 12px;
}

.app img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
    margin-bottom: 10px;
}

.app:hover {
    background-color: #233854;
}

.menu-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: rgba(38, 43, 58, 0.5);
    padding-top: 20px;
    margin-top: 10px;
}

.menu-box svg {
    margin: 0 auto;
}

.main-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #1B202A;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-button-enter {
    width: 100%;
    color: #0DBC79;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    border: 2px solid #0DBC79;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 5px;
}

.bottom-blank {
    height: 20px;
}
</style>