<template>
    <div class="main">

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.main {
    display: flex;
    padding: 20px;
    font-family: "cloud";
    box-sizing: border-box;
    min-height: 100vh;
}

</style>