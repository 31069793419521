<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
div::-webkit-scrollbar {
    display: none;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    flex-shrink: 0;
}

@font-face {
    font-family: "cloud";
    src: url("./assets/cloud.ttf");
}

.contain {
    max-width: 1920px;
    min-width: 1440px;
    max-height: 1080px;
    min-height: 600px;
    width: 100vw;
    height: 100vh;
    font-family: "cloud";
    overflow-y: scroll;
    margin: 0 auto;
}
</style>
