import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Index from "@/pages/Index"
import Detail from "@/pages/Detail"
import Purchase from "@/pages/Purchase"
import Mobile from "@/pages/Mobile"
import Plugin from "@/pages/Plugin"


const routes = [
    {
        path: "/",
        component: Index
    },
    {
        path: "/detail",
        component: Detail
    },
    {
        path: "/purchase",
        component: Purchase
    },
    {
        path: "/mobile",
        component: Mobile
    },
    {
        path: "/plugin",
        component: Plugin
    }
]
const router = new VueRouter({
    routes,
    mode:'history',
})

router.beforeEach((to, from, next) => {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        if (to.path != '/mobile') {
            next({ path: '/mobile' })
        } else {
            next()
        }
    } else {
        if (to.path == '/mobile') {
            next({ path: '/' })
        } else {
            next()
        }
    }
})

export default router