<template>
    <div class="contain">
        <HeaderMenu></HeaderMenu>
        <ChatBox></ChatBox>
        <BottomMenu></BottomMenu>
    </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu"
import BottomMenu from "@/components/BottomMenu"
import ChatBox from "@/components/ChatBox"
export default {
    components: { HeaderMenu, BottomMenu, ChatBox },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {
        console.log("index")
    }
}
</script>

<style scoped>
.contain {
    position: relative;
    background-color: #10151B;
    font-family: "cloud";
}
</style>